
// Portfolio
// 
.portfolio{
  .btn:focus,.btn:hover{
    box-shadow: none!important;
  }
  .btn.active{
    background: $primary-color;
    color: $light;
  }
  .btn{
    border-radius: 4px!important;
  }

  .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

 .inner-box{
  position:relative;
}

.inner-box .image{
  position:relative;
}

.inner-box .image img{
  position:relative;
  width:100%;
  display:block;
}

.inner-box .image .overlay-box{
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    z-index: 2;
    opacity: 0;
    display: block;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
    transition: all 900ms ease;
}

.inner-box .image .overlay-box:before{
  position:absolute;
  content:'';
  right:0px;
  top:0px;
  width:50%;
  height:100%;
   display:block;
  opacity:0; 
    -webkit-transform: perspective(400px) rotateY(-90deg);
   -moz-transform: perspective(400px) rotateY(-90deg); 
    -ms-transform: perspective(400px) rotateY(-90deg);
    -o-transform: perspective(400px) rotateY(-90deg); 
    transform: perspective(400px) rotateY(-90deg); 
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color:$primary-color;
}

.inner-box:hover .image .overlay-box:before{
  opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
}

.inner-box .image .overlay-box:after{
  position:absolute;
  content:'';
  left:0px;
  top:0px;
  width:50%;
  height:100%;
  display:block;
  opacity:0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: right;
    -o-transform-origin: left;
    transform-origin: left;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color:$primary-color;
}

.inner-box:hover .image .overlay-box:after{
  opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
}

 .inner-box:hover .image .overlay-box{
  opacity: 1;
}

 .inner-box .image .overlay-box .overlay-inner{
  position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    text-align: left;
    z-index:1;
    padding: 10px 40px;
    vertical-align: middle;
}


 .inner-box .image .overlay-box .overlay-inner .overlay-content{
    position: relative;
    display: table-cell;
    vertical-align: middle;
}


 .inner-box .image h3{
  color:$light;
}
