
.navigation{
  position: absolute;
  width: 100%;
  z-index: 9999;
}
#navbar{
  li{
    padding-left: 15px;
  }

 .nav-link{
    font-family: $secondary-font;
    text-transform: capitalize;
    color: #f5f5f5;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 18px;
  line-height: 26px;
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;
    
    &:hover, &:focus{
      color: $primary-color;
    }
  }


  .btn{
    padding: .5rem 1.5rem;
  }

}

.header-top{
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255,255,255,.05);

  .header-top-socials{
    border-left: 1px solid rgba(255, 255, 255, 0.05);

     a{
        margin-right: 15px;
        color: rgba(255,255,255,.85);
        font-size: 14px;
    }
 }

  .header-top-info{
    a{
      color: rgba(255,255,255,.85);
      padding-right: 35px;
       font-size: 14px;
    }

    a:first-child{
      padding-left: 0px;
    }
  }
}

.text-black{
  color: $black;
}

.quote-btn{
    display: inline-block;
    padding: 6px 15px;
    font-size: 13px;
}

.rounded-btn{
  border-radius: 4px;
}
#navbar .navbar-brand{
  color: $light;
  font-weight: 700;
}
.dropdown{
  position: relative;
}
.dropdown-menu{
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  width: 250px;
  margin-top: 15px;
  padding: 0px;
  border-radius: 0px;
  border-top: 3px solid $primary-color;
  background: $black;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
  webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  position: absolute !important;
  left: 0px;
  top: 100%;
  width: 240px;
  z-index: 100;
  display: block !important;
  margin: 0 auto;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown:hover .dropdown-menu{
    visibility: visible;
    opacity: 1;
       webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
      transform: translateY(0px);
}
.dropdown-item{
    padding: 9px 23px;
    text-transform: uppercase;
    font-size: 14px;
    color: $light!important;
     -webkit-transition: all .45s ease 0s;
       -moz-transition: all .45s ease 0s;
        -ms-transition: all .45s ease 0s;
         -o-transition: all .45s ease 0s;
            transition: all .45s ease 0s;
}

.dropdown-item:hover{
  background: $primary-color;
  color: $light;
}

ul.dropdown-menu li {
    padding-left: 0px!important;
}

.nav-top{
   position: fixed;
  padding: 1em 30px;
  top: 0;
  width: 100%;
 background-image: -moz-linear-gradient(rgb(46, 50, 56) 98px, rgba(0, 0, 0, 0) 98px);
 background-image: -o-linear-gradient(rgb(46, 50, 56) 98px, rgba(0, 0, 0, 0) 98px);
 background-image: -webkit-linear-gradient(rgb(46, 50, 56) 98px, rgba(0, 0, 0, 0) 98px);
 background-image: -ms-linear-gradient(top, #2e3238 98px, transparent 98px);
 background-image: linear-gradient(rgb(46, 50, 56) 98px, rgba(0, 0, 0, 0) 98px);
  -webkit-transition: .0s all ease;
  -o-transition: .0s all ease;
  transition: .0s all ease;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.nav-top.awake {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}

.nav-top.sleep {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translateY(-80%);
    -ms-transform: translateY(-80%);
    transform: translateY(-80%);
}
.navbar-collapse{
   text-align: left;
}
.navbar-collapse.collapse.show{
  background: $black;
}

a.bg-primary:hover{
  background: $primary-color!important;
}


@include mobile{
  .navbar-collapse{
     text-align: center;
     padding: 20px;
  }
}

@include mobile-xs{
  .navbar-collapse{
     text-align: center;
     padding: 20px;
  }
}
@include tablet{
  .navbar-collapse{
     text-align: center;
     padding: 20px;
  }
}
